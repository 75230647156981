// Sidebar dark

.app-sidebar {

  &--dark {
    .app-sidebar--header {
      background: white;
      margin-bottom: 0;
      border-bottom: 1px solid #f0f5f5;

      .collapse-sidebar-btn {
        background: transparent;
        color: rgba($white, .8);
        opacity: 1;

        &:hover {
          background: rgba($black, .2);
          color: $white;
        }
      }
      .expand-sidebar-btn {
        border: $white solid 2px;
        background: $white;
        color: $primary;
        box-shadow: $box-shadow-sm;
      }
      &::after {
        background: rgba($white, .15);
      }
    }

    // Logo wrapper

    .app-sidebar-logo {
      &--icon {
        background: $white;
        box-shadow: 1px 1px 3px rgba($black, .2);
      }

      &--text {
        span {
          color: $white;
          opacity: .7;
        }

        b {
          color: $white;
        }
      }

      &:hover {
        color: $black;
      }
    }

    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      background: $white !important;
    }
  }
}

.height-100-vh {
  min-height: 100vh;
}

.p-fixed {
  position: fixed;
}

.collapse-expand-btn {
  margin-left: calc(100% - 30px) !important;
}

.font-10 {
  font-size: 10px !important;
  font-family: $font-family-quicksand !important;
}

.font-12 {
  font-size: 12px !important;
  font-family: $font-family-quicksand !important;
}

.font-14 {
  font-size: 14px !important;
  font-family: $font-family-quicksand !important;
}

.font-16 {
  font-size: 16px !important;
  font-family: $font-family-quicksand !important;
}

.font-18 {
  font-size: 18px !important;
  font-family: $font-family-quicksand !important;
}

.font-24 {
  font-size: 24px !important;
  font-family: $font-family-quicksand !important;
}

.cursor-pointer {
  cursor: pointer;
}

.fixed {
  position: fixed;
}

.height-300 {
  height: 300px;
};

.width-200 {
  min-width: 200px;
}

.link:hover {
  color: #6e3cfa !important;
}

body {
  font-family: $font-family-quicksand;
}
