@import "../../../../assets/base.scss";

.coverageTable {
  width: 100%;
  overflow-x: auto;
}

.highlight-technology {
  background-color: #edecec;
  margin-right: 5px;
}

.boxContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  right: 16px;
}

.fpcScoreBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  color: white;
}

.fpcScore {
  font-size: 12px;
}

.flagColor {
  width: 18px;
  height: 18px;
  border-radius: 2px
}
